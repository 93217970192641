<template>
  <div class="inner">
    <el-upload
      :action="uploadApi"
      :limit="limitNum"
      :headers="uploadHeader"
      :class="{disUploadSty:hideUploadEdit}"
      list-type="picture-card"
      :on-remove="handleRemove"
      :on-change="handleEditChange"
      accept="image/jpeg,image/gif,image/png"
      :file-list="fileList"
      :data="{type:uploadType}"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    </div>
</template>

<script>
import {imgDel} from "@/api/apis";
export default {
  name: "",
  props: {
      api:"", //接收参数
      type:""
  },
  data() {
    return {
      imgUrl:"",
      uploadApi:this.api,
      uploadType:this.type,
      hideUploadEdit: false,  // 隐藏'上传按钮'
      limitNum: 1,           // 图片数量
      fileList: []           // 图片列表
    };
  },

  components: {},
  created() {},
  computed: {
    uploadHeader() {
      return{
        "Authorization": "Bearer " + localStorage.token // 直接从本地获取token就行
      }
    }
  },
  methods: {

    handleEditChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= this.limitNum;
      this.fileList = fileList
      // console.log("this.fileList:", this.fileList);
      // console.log("this.hideUploadEdit:", this.hideUploadEdit);
    },

    uploadPreview(file) {

      const isImage = this.globalfunc.matchFileType(file.name) === "image"
      const isLt2M = file.size > 2 * 1024 *1024;

      if (!isImage) {
        this.$message.error("上传图片只能是 png jpg jpeg bmp gif 格式!");
        return false;
      };
      if (isLt2M) {
        this.$message.error("上传图片大小不能超过 2M!");
        return false;
      };
      
      let pxisok = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (theFile) => {
        const image = new Image();
        image.src = theFile.target.result;
        image.onload = () => {
          const { width, height } = image;
          if(width > 1280 || height > 960) {
            this.$message.error("请上传小于 1280*960 px 的图片！");
            pxisok = false;
          };
        };
      };
      return isImage && !isLt2M && pxisok;
    },

    handleRemove(file, fileList) {
      //console.log(file)
      let imgs = [];
      if(file.response.lgImg)
        imgs.push(file.response.lgImg);
      if(file.response.mdImg)
        imgs.push(file.response.mdImg);

      let src = JSON.stringify(imgs);
      imgDel(src).then((res) => {
        if(res.data.status == true) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
        }
      }).catch(() => {
          this.$message({
            type: "info",
            message: "删除失败",
          });
          return false;
      });

      if (fileList.length === 0) {
        this.fileList = [];
      } else {
        let dl = this.fileList.indexOf(file);
        this.fileList.splice(dl, 1);
      }
      this.hideUploadEdit = fileList.length >= this.limitNum;
    },

    handleUpload(param) {
      this.param = param;
      // 这里可以进行上传
      // let formData = new FormData(); //formdata格式
      // formData.append("fileName", this.param.file);
      // 将formData 作为 body 上传即可， 有额外的参数可携带
    },

    submitUpload() {
      if (!this.param) {
        this.$message("请选择图片");
      } else {
        let formData = new FormData(); //formdata格式
        formData.append("file", this.param.file);
        // 也可以在这里进行上传
        // let formData = new FormData(); //formdata格式
        // formData.append("fileName", this.param.file);
        // 将formData 作为 body 上传即可， 有额外的参数可携带
      }
    }
  },
};
</script>
<style scoped>
.disUploadSty .el-upload--picture-card {
    display: none;
}
</style>
