import axios from '@/utils/request'; // 导入http中创建的axios实例


const IP='https://lxj18.top'

//请求基本路径
axios.defaults.baseURL=IP + '/api';

//商品图片上传接口地址
export const IMG_UPLOAD_API = IP + '/api/admin/upload/common'

//上传店铺头像
export const SHOP_IMG_PATH = IP + '/api/shop/'

//管理员头像
export const AVATAR_IMG_PATH = IP + '/api/avatar/'

// 图片删除
export var imgDel=(src)=>axios.delete('/admin/upload/', {params:{src}})

// 上传文件

//  上传商品图片




//登录  account,password  账户 密码
export function login(username,password){
   return axios.post("/admin/login",{username:username,password:password})
//    return axios.post("/user/login",{username:username,password:password})
}

// -----------------------账号管理API------------------------
export var useradd=(account,password,fullname,sex,tel)=>axios.post('/admin/register',{account,password,fullname,sex,tel})
//  用户列表
export var userlist=(currentPage,pageSize)=>axios.get('/admin/list',{params:{currentPage,pageSize}})
//  删除用户
export var userdel=(id)=>axios.delete('/admin',{params:{id}})
//  批量删除用户
export var userbatchdel=(ids)=>axios.get('/admin/batchdel',{params:{ids}})
//  修改用户信息
export var useredit=(id, fullname, sex, avatar, tel, email)=>axios.put('/admin/account',{id, fullname, sex, avatar, tel, email})
//  获取个人信息 头像
export var accountinfo=(id)=>axios.get('/admin',{params:{id}})


//  获取商品分类
export var categories=(pageIndex=0,pageSize=0)=>axios.get('/category/all',{params:{pageIndex,pageSize}})
//  添加分类
export var addcate=(params)=>axios.post('/category',params)
//  修改分类
export var editcate=(params)=>axios.put('/category',params)
//  删除分类
export var delcate=(id)=>axios.delete('/category',{params:{id}})


//  添加商品
export var addgoods=(params)=>axios.post('/admin/goods',params)
//  获取商品列表
export var goodslist=(pageIndex,pageSize) => axios.get('/admin/goods/list',{params:{pageIndex,pageSize}})

//  获取商品详情
export var goodsdetail=(id) => axios.get('/admin/goods/',{params:{id}})

//  删除商品
export var goodsdel=(id)=>axios.delete('/admin/goods',{params:{id}})

//  修改商品
export var goodsedit=(params)=>axios.put('/admin/goods',params)

//  订单管理
export var orderlist = (params) => axios.get('/admin/order/list', { params })


//  报表api
export var echartss=()=>axios.get('/admin/order/list', { status:'all' })

//  订单数据显示
export var orderechasrts=(date)=>axios.get('/admin/order/list',{params:{date}})

// ======================店铺管理=================================
// 获取店铺详情
export var shopsman=()=>axios.get('/shop/info')
// 28. 店铺内容修改
export var shopsedit=(params)=>axios.post('/shop/edit',params)
// export login  多次暴露  接收 import {} from 'xxx
// export default xxx  只能暴露一个内容  接 import xxx from 'xxx  若要暴露多了 可以添加对象

//
